import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { BsSearch } from "react-icons/bs";
import { getUserChannels } from "../../features/mediationCases/channelsSlice";

interface SearchChannelProps {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
}

const SearchChannel: React.FC<SearchChannelProps> = ({
  searchInput,
  setSearchInput,
}) => {
  const localSearch = localStorage.getItem("search");
  // const [searchInput, setSearchInput] = useState(localSearch || "");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const dispatch = useAppDispatch();
  const { channels, isLoading, error, isError, isSuccess, message } =
    useAppSelector((state) => state.channels);
  const filteredChannels = channels.channels;

  useEffect(() => {
    if (!searchInput) {
      return;
    }

    const newTimeout: NodeJS.Timeout = setTimeout(() => {
      dispatchSearch(searchInput);
    }, 1000);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(500);

    return () => {
      if (newTimeout) {
        clearTimeout(newTimeout);
      }
    };
  }, [dispatch, searchInput]);

  function handleSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    const inputValue = event.target.value;
    localStorage.setItem("search", inputValue);

    setSearchInput(inputValue);
    if (!inputValue) dispatch(getUserChannels({}));
  }

  function dispatchSearch(value: string) {
    dispatch(getUserChannels({ title: value }));
  }

  return (
    <div className='sm:w-[35%] flex items-center bordfer border-green-400'>
      <div className='flex justify-between items-center flex-1 gap-3 py-2 px-4 text-[#6A6A6A] border sm:border-b sm:border-x-0 sm:border-t-0 border-[#D0D5DD] bg-[#F8F8F8] rounded-lg sm:rounded-none'>
        <BsSearch className='' />
        <input
          type='text'
          className='flex-1 bg-[#F8F8F8] outline-none'
          placeholder='Search for mediator, case ID'
          onChange={handleSearchInputChange}
          value={searchInput}
        />
      </div>
    </div>
  );
};

export default SearchChannel;

import React, { ChangeEvent } from "react";

interface SelectProps {
  label: string;
  name: string;
  placeholder?: string;
  options: { value: string; label: string }[];
  className?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  error?: string;
}

export default function Select({
  label,
  options,
  className,
  placeholder,
  name,
  onChange,
  value,
  error,
}: SelectProps) {
  return (
    <div className={className}>
      <div className='md:text-lg text-sm font-normal text-[#040821] mb-1 mx-1'>
        <label>{label}</label>
      </div>

      <select
        id={label}
        className='w-full h-[45px] border border-[#00000033] px-5 bg-transparent rounded-md outline-none'
        name={name}
        value={value}
        onChange={onChange}>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.value === "disabled"}
            selected={option.value === "disabled" ? true : false} //find wayaround this
            defaultValue={"disabled"}>
            {option.label}
          </option>
        ))}
      </select>
      {error && (
        <span className='error-text text-[#FF0101] text-[14px]'>{error}</span>
      )}
    </div>
  );
}

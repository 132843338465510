import { AsyncThunkAction } from "@reduxjs/toolkit";
import apiRequest from "../../hooks/use-request";

interface ErrorResponseType {
  message: string;
}

function isErrorResponseType(error: unknown): error is ErrorResponseType {
  return typeof error === "object" && error !== null && "message" in error;
}

const authenticate = async (sessionId: string) => {
  const { makeRequest: authenticateUser } = apiRequest("/auth/user", "POST");
  const response = await authenticateUser({ sessionId }, undefined);

  if (response.data) {
    localStorage.setItem("token", response.data.token);
  }

  return { user: response.data.user, token: response.data.token };
};

const authService = {
  authenticate,
  isErrorResponseType,
};

export default authService;

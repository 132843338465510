const Error404 = () => {
  return (
    <div className='flex flex-col gap-6 h-[60vh] justify-center md:items-center px-5'>
      <p className='font-bold text-[22px] text-center'>Oops!</p>
      <p className='text-md text-center'>Page not found</p>
    </div>
  );
};

export default Error404;

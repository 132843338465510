import React from "react";
import Button from "./button";

interface CardProps {
  title: string;
  route?: string;
  totalCount: string | number;
  image: JSX.Element;
}

const Card: React.FC<CardProps> = ({ image, title, totalCount, route }) => {
  return (
    <div className='grow bg-[#FFFFFF] text-base sm:text-lg rounded-lg shadow-2xl shadow-inherit min-w-64 max-w-80 p-4 px-6 sm:p-6 sm:px-10 '>
      <div className='flex flex-col gap-3 '>
        <p>{title}</p>
        <p className='text-xl sm:text-2xl font-bold'>{totalCount}</p>
        <div className='flex justify-between items-center '>
          <Button
            href={route}
            variant='primary'
            size='sm'
            className='text-xs sm:text-sm'>
            View All
          </Button>
          {image}
        </div>
      </div>
    </div>
  );
};

export default Card;

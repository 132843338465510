import React from "react";
import Button from "../button";
import { getStatusBgColor, getStatusDotColor } from "../../utils/status-color";

interface ListItemProps {
  caseID: string | number;
  reqTitle: string;
  createdAt: string;
  status: string;
  chatID: string;
  flex?: string;
  from?: string;
}

interface StyleWidths {
  containerFlex: string;
  detailsWidth: string;
}

function getWidth(from: string) {
  switch (from) {
    case "/requests":
      return {
        containerFlex: "lg:w-[47%]",
        detailsWidth: "max-w-[28%] min-w-[28%] ",
      };
    default:
      return {
        containerFlex: "",
        detailsWidth:
          "max-w-[28%] min-w-[28%] sm:max-w-[20%] sm:min-w-[20%] md:max-w-[15%] md:min-w-[15%] ",
      };
  }
}

const ListItem: React.FC<ListItemProps> = ({
  caseID,
  reqTitle,
  status,
  chatID,
  createdAt,
  flex,
  from,
}) => {
  const style: StyleWidths | any = from && getWidth(from);

  return (
    <div
      className={`flex 
       ${style?.containerFlex} justify-between gap-4 text-sm sm:text-lg p-4 sm:px-12 sm:py-6 border border-[#00000080] rounded-lg `}>
      <div className='flex flex-col'>
        <p className='flex'>
          <span className='font-medium'>Case ID:</span>
          <span className='flex-1 ml-3 sm:ml-4'>{caseID}</span>
        </p>
        <p className='flex'>
          <span className='font-medium'>Request title:</span>
          <span className='flex-1 ml-3 sm:ml-4'>{reqTitle}</span>
        </p>
        <p className='flex'>
          <span className='font-medium'>Date:</span>
          <span className='flex-1 ml-3 sm:ml-4'>
            {new Date(createdAt).toDateString()}
          </span>
        </p>
      </div>
      <div
        className={`min-w-[25%] sm:min-w-0 sm:grow max-w-36 flex flex-col justify-between text-xs sm:text-sm text-center ${style?.detailsWidth} `}>
        <div
          className={`${getStatusBgColor(
            status
          )} py-1 rounded-full text-center flex items-center justify-center`}>
          <div
            className={`w-1 h-1 inline mr-2 rounded-full ${getStatusDotColor(
              status
            )}`}></div>
          <span className='capitalize'>{status}</span>
        </div>
        <Button variant='primary' size='xs' href={`/request/details/${chatID}`}>
          View details
        </Button>
      </div>
    </div>
  );
};

export default ListItem;

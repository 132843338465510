import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import chatService from "./chatService";
import type { RootState } from "../../app/store";
import { string } from "slate";

interface Chat {
  chat: any;
  error: string;
  message?: string;
  attachment?: string[];
}

const initialState: Chat = {
  chat: null,
  error: "",
};

// Send message in a chat channel
export const sendChat = createAsyncThunk(
  "/mediation/sendChat",
  async (userData: any, thunkAPI) => {
    try {
      const response = await chatService.sendChat(userData);
      return response;
    } catch (error: unknown) {
      if (chatService.isErrorResponseType(error)) {
        return thunkAPI.rejectWithValue(JSON.parse(error.message));
      } else {
        console.error("Unknown error occurred:", error);
        return thunkAPI.rejectWithValue(`Unknown error occurred: ${error}`);
      }
    }
  }
);

// Get chat for a user channel
export const getChat = createAsyncThunk(
  "/mediation/getChat",
  async (userData: any, thunkAPI) => {
    try {
      const response = await chatService.getChat(userData);
      return response;
    } catch (error: unknown) {
      if (chatService.isErrorResponseType(error)) {
        return thunkAPI.rejectWithValue(JSON.parse(error.message));
      } else {
        console.error("Unknown error occurred:", error);
        return thunkAPI.rejectWithValue(`Unknown error occurred: ${error}`);
      }
    }
  }
);

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    reset: (state) => {
      state.message = "";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendChat.fulfilled, (state, action) => {
        // state.channels = action.payload.data;
      })
      .addCase(sendChat.rejected, (state, action) => {
        // state.isLoading = false;
        // state.isError = true;
        // state.error = action.payload as string;
        // state.channels = [];
      })
      .addCase(getChat.fulfilled, (state, action) => {
        state.chat = action.payload.data;
      })
      .addCase(getChat.rejected, (state, action) => {
        // state.isLoading = false;
        // state.isError = true;
        state.error = action.payload as string;
        state.chat = null;
      });
  },
});

export const { reset } = chatSlice.actions;
export const selectCount = (state: RootState) => state.channels;
export default chatSlice.reducer;

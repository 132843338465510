import React from "react";
import { PulseLoader } from "react-spinners";

interface SpinnerProps {
  size?: number;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 40 }) => {
  return (
    <div className='flex justify-center items-center'>
      <PulseLoader color='#000000' />
    </div>
  );
};

export default Spinner;

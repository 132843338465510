import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import channelsService from "./channelsService";
import type { RootState } from "../../app/store";

interface Channel {
  defendant: {
    isResponseAble: boolean;
  };
  claimant: {
    fullName: string;
    email: string;
    isResponseAble: boolean;
  };
  title: string;
  caseID: string;
  transactionNumber: string;
  description: string;
  attachment: string[];
  platform: string;
  active: boolean;
  status: string;
  priority: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: 0;
}

interface SliceState {
  channels: {
    page: number;
    totalPages: number;
    count: number;
    channels: Channel[];
  };
  statistics?: {
    all: number;
    active: number;
    closed: number;
  };
  isError: boolean;
  error: string | any;
  isSuccess: boolean;
  isLoading: boolean;
  message: string | unknown;
}

const initialState: SliceState = {
  channels: { page: 1, totalPages: 1, count: 1, channels: [] },
  statistics: {
    all: 0,
    active: 0,
    closed: 0,
  },
  isError: false,
  error: "",
  isSuccess: false,
  isLoading: false,
  message: "",
} as SliceState;

// Get all user channels
export const getUserChannels = createAsyncThunk(
  "/mediation/getChannels",
  async (queryParams: any, thunkAPI) => {
    try {
      const response = await channelsService.getChannels(queryParams);
      return response;
    } catch (error: unknown) {
      if (channelsService.isErrorResponseType(error)) {
        return thunkAPI.rejectWithValue(JSON.parse(error.message));
      } else {
        console.error("Unknown error occurred:", error);
        return thunkAPI.rejectWithValue(`Unknown error occurred: ${error}`);
      }
    }
  }
);

// Create a new user channel (mediation request)
export const createChannel = createAsyncThunk(
  "/mediation/createChannel",
  async (userData: any, thunkAPI) => {
    try {
      const response = await channelsService.createChannel(userData);
      return response;
    } catch (error: unknown) {
      if (channelsService.isErrorResponseType(error)) {
        return thunkAPI.rejectWithValue(JSON.parse(error.message).message);
      } else {
        console.error("Unknown error occurred:", error);
        return thunkAPI.rejectWithValue(`Unknown error occurred: ${error}`);
      }
    }
  }
);

export const channelsSlice = createSlice({
  name: "channels",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.error = "";
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserChannels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserChannels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.channels = action.payload.response.data;
        state.statistics = action.payload.statistics.data;
      })
      .addCase(getUserChannels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload as string;
        state.channels.channels = [];
      })
      .addCase(createChannel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createChannel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.channels.channels.unshift(action.payload.data);
        state.message = action.payload.message;
      })
      .addCase(createChannel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload as string;
      });
  },
});

export const { reset } = channelsSlice.actions;
export const selectCount = (state: RootState) => state.channels;
export default channelsSlice.reducer;

import { AsyncThunkAction } from "@reduxjs/toolkit";
import apiRequest from "../../hooks/use-request";
import fileUpload from "../../hooks/use-upload";

const token = localStorage.getItem("token");

interface ErrorResponseType {
  message: string;
}

function isErrorResponseType(error: unknown): error is ErrorResponseType {
  return typeof error === "object" && error !== null && "message" in error;
}

const getChat = async (userData: any) => {
  const { makeRequest: getChat } = apiRequest(`/channels/${userData}`, "GET");
  const response = await getChat(userData);

  return response;
};

const sendChat = async (userData: any) => {
  const { makeRequest: sendChat } = apiRequest("/message/send", "POST");
  const response = await sendChat(userData);

  return response;
};

const channelsService = {
  sendChat,
  getChat,
  isErrorResponseType,
};

export default channelsService;

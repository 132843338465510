import React, { useEffect } from "react";
import Back from "../../components/back";
import avatar from "../../assets/images/chat_avatar.png";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import {
  getNotifications,
  reset,
} from "../../features/notifications/notificationSlice";
import { getTimeAgo } from "../../utils/helper-functions";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(getNotifications({}));
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <div className='px-4'>
      <div className='flex justify-between gap-6 sm:gap-10 items-center'>
        <div className='flex items-center gap-6'>
          <div>
            <Back />
          </div>
          <p className='font-bold text-sm sm:text-base'>Notifications</p>
        </div>
      </div>
      <div className='my-3 mb-8'>
        <div className='flex justify-between my-4 px-2'>
          <p>{""}</p>
          <p>{""}</p>
        </div>
        <div className='flex flex-col gap-3'>
          {notifications.notifications.length > 0 &&
            notifications.notifications.map((notification) => (
              <div
                key={notification?._id}
                id={notification?._id}
                className='flex justify-between border border-[#D0D5DD80] rounded-lg p-8 px-12'>
                <div className='flex gap-6 basis-[80%]'>
                  <div className='basis-10%'>
                    <img
                      src={avatar}
                      alt='avatar'
                      className='w-8 sm:w-13 md:w-16'
                    />
                  </div>
                  <div className='flex flex-col'>
                    <div className='flex-1 flex flex-col gap-1'>
                      <div className='flex justify-between items-center'>
                        <div className='text-sm md:text-lg font-semibold py-2'>
                          {notification?.title}
                        </div>
                      </div>
                    </div>
                    <div className='text-sm md:text-base text-justify p-4 bg-[#F1F1F1]'>
                      {notification?.content}
                    </div>
                  </div>
                </div>
                <div className='text-[10px] md:text-sm italic '>
                  {getTimeAgo(notification?.createdAt)}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default Notifications;

import { DocumentText, More, People } from "iconsax-react";
import logo from "../assets/images/logo.png";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { Modal } from "antd";

const ChatNavbar = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { id } = useParams();

  return (
    <div>
      <div className='bg-[#FFFFFF]'>
        <div className='max-w-screen-xl mx-auto p-4 flex justify-between'>
          <Link to='/dashboard'>
            <img src={logo} alt='logo' />
          </Link>
          <More
            size='24'
            color='#98A2B3'
            onClick={() => setIsModalVisible(!isModalVisible)}
          />
        </div>
      </div>
      <div className='max-w-screen-xl relative'>
        <Modal
          open={isModalVisible}
          footer={null}
          closeIcon={null}
          onCancel={() => setIsModalVisible(false)}
          className='flex justify-end px-auto absolute top-12 right-5 md:right-[2rem] lg:right-[7rem]'>
          <div className='-my-2 flex justify-center items-center mx-auto flex-col gap-6'>
            <div className='flex flex-col gap-4 text-black text-base font-medium'>
              {/* <Link
                to={`/request/chat/${chatId}`}
                className='flex gap-3 items-center hover:text-[#000000AF]'>
                <DocumentText size='25' color='#000000' />
                <p className='c'>View form request details</p>
              </Link> */}
              <Link
                to={`/request/details/${id}`}
                className='flex gap-3 items-center hover:cursor-pointer hover:text-[#000000AF]'>
                <People size='25' color='#000000' />
                <p className='c'>View request details</p>
              </Link>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ChatNavbar;

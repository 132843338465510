import { BsSearch } from "react-icons/bs";
import avatar from "../../assets/images/avatar.png";
import dining from "../../assets/images/dining.png";
import Button from "../../components/button";
import Card from "../../components/card";
import List from "../../components/list/list";
import {
  getUserChannels,
  reset,
} from "../../features/mediationCases/channelsSlice";
import { useAppSelector, useAppDispatch } from "../../app/hook";
import { useNavigate } from "react-router";
import ListItem from "../../components/list/listItem";
import { useEffect, useLayoutEffect, useState } from "react";
import { error } from "console";
import { ToastContent, toast } from "react-toastify";
import SearchChannel from "./SearchChannel";
import classNames from "classnames";
import Pagnition from "../../components/pagnition";
import Spinner from "../../components/spinner/spinner";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const {
    channels,
    statistics,
    isLoading,
    error,
    isError,
    isSuccess,
    message,
  } = useAppSelector((state) => state.channels);
  const { channels: filteredChannels, page, count, totalPages } = channels;
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (filteredChannels.length) return;
    if (isError && error?.message) {
      toast.error(error?.message as ToastContent);
    }
    if (error?.statusCode >= 401) {
      dispatch(reset());
      navigate("/401");
    } else {
      dispatch(getUserChannels({}));
    }
    return () => {
      dispatch(reset());
    };
  }, [isError, navigate, dispatch]);

  // if (isLoading) {
  //   return <>asdfsdf</>;
  // }

  return (
    <>
      <div className='my-2 sm:my-8'>
        <div className='flex flex-col sm:flex-row justify-between gap-4 sm:gap-6'>
          <div className='flex flex-row items-center gap-4 lg:gap-6'>
            <img
              src={avatar}
              alt='avatar'
              className='rounded-full border border-solid w-12 h-12 sm:w-16 sm:h-16 '
            />
            <p className='font-bold text-sm sm:text-xl text-[#000000]'>
              Welcome to the Mediation Center
            </p>
          </div>
          <SearchChannel
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
        </div>
      </div>
      <div className='my-2 sm:my-6 flex flex-col sm:flex-row gap-x-10 items-center justify- sm:justify-between'>
        <div className='text-2xl font-medium hidden sm:block'>Overview</div>
        <div className='flex w-full sm:w-[55%] md:w-[45%] lg:max-w-[30%]'>
          <Button
            href='/requests/create'
            variant='primary'
            size='md'
            className='w-full text-sm sm:text-md grow'>
            + Create New Mediation Request
          </Button>
        </div>
      </div>
      <div className='my-8'>
        <div className='flex gap-6 md:gap-8 overflow-x-auto scroll-smooth'>
          <Card
            title='All Requests'
            totalCount={statistics?.all || "NIL"}
            route='/requests/all'
            image={<img src={dining} alt='meeting table' className='' />}
          />
          <Card
            title='Active Requests'
            totalCount={statistics?.active || "NIL"}
            route='/requests/active'
            image={<img src={dining} alt='meeting table' className='' />}
          />
          <Card
            title='Closed Requests'
            totalCount={statistics?.closed || "NIL"}
            route='/requests/closed'
            image={<img src={dining} alt='meeting table' className='' />}
          />
        </div>
      </div>
      <div className='flex flex-col gap-4 sm:gap-6 my-8 mb-4'>
        <p className='font-bold'>Recent Requests</p>
        {isLoading ? (
          <div className='flex justify-center items-center w-full'>
            <Spinner />
          </div>
        ) : filteredChannels?.length === 0 ? (
          <div className='flex justify-center py-4 sm:py-8'>
            <div className='flex flex-col gap-4 w-[80%] sm:w-[60%] md:w-[40%]'>
              <p className='text-center'>You have no recent requests</p>
              <Button
                href='/requests/create'
                variant='primary'
                size='lg'
                className='w-[100%]'>
                Create New Request
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <List />
          </div>
        )}
      </div>
      {totalPages > 1 && (
        <Pagnition
          page={page}
          totalPages={totalPages}
          otherParams={{ title: searchInput }}
          dispatch={dispatch}
          getUserChannels={getUserChannels}
        />
      )}
    </>
  );
};

export default Dashboard;

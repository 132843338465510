import classNames from "classnames";
import React, { ChangeEvent } from "react";

interface TextareaProps {
  label?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string;
  readOnly?: boolean;
}

export default function Textarea({
  label,
  placeholder,
  className,
  value,
  onChange,
  error,
  readOnly = false,
}: TextareaProps) {
  return (
    <div className={`flex flex-col gap- ${className}`}>
      {label && (
        <div className='md:text-lg text-sm font-normal text-[#040821] mb-1 mx-1'>
          {label}
        </div>
      )}

      <textarea
        placeholder={placeholder}
        className={classNames(
          "text-field w-full h-[100px] border border-[#00000033] p-5 bg-transparent rounded-md outline-none",
          { error: error }
        )}
        onChange={onChange}
        value={value}
        readOnly={readOnly}
      />

      {error && (
        <span className='error-text text-[#FF0101] text-[14px]'>{error}</span>
      )}
    </div>
  );
}

import { Modal } from "antd";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hook";
import {
  getTimeAgo,
  truncateStringToFitContainer,
} from "../utils/helper-functions";
import { useEffect, useState } from "react";
import { Notification } from "iconsax-react";
import {
  getNotifications,
  reset,
} from "../features/notifications/notificationSlice";

const Navbar = () => {
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { notifications } = useAppSelector((state) => state.notifications);

  useEffect(() => {
    dispatch(getNotifications({}));

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <div className='bg-[#FFFFFF]'>
      <div className='max-w-screen-xl mx-auto p-4'>
        <div className='flex justify-between'>
          <Link to='/dashboard' className=' px-2'>
            <img src={logo} alt='logo' />
          </Link>
          <div
            className='rounded-lg border border-[#00000080] p-1 px-2'
            onClick={() => setIsModalVisible(!isModalVisible)}>
            <Notification size={28} color='#000000' />
          </div>
        </div>
        <div className='w-screen-xl relative'>
          <Modal
            open={isModalVisible}
            footer={null}
            closeIcon={null}
            onCancel={() => setIsModalVisible(false)}
            className='flex justify-end absolute px-auto absolute top-16 right-2 md:right-[2rem] lg:right-[3rem] xl:right-[9rem] border'>
            <div className='-my-2 flex flex-col justify-center gap-6 text-black font-mont'>
              <p className='border-b text-lg font-bold py-2 w-full'>
                Notifications
              </p>
              {notifications.notifications.length > 0 ? (
                notifications.notifications.slice(0, 3).map((notification) => (
                  <div className='flex flex-col gap-1'>
                    <div className='flex justify-between gap-8 items-center'>
                      <div className='text-base'>
                        {truncateStringToFitContainer(
                          notification.title ?? "",
                          500
                        )}
                      </div>
                      <div className='italic basis-[%]'>
                        {getTimeAgo(notification?.createdAt)}
                      </div>
                    </div>
                    <Link
                      to={`/notifications/#${notification?._id}`}
                      className='hover:text-gray-500'>
                      <div className='text-sm'>View Details</div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className='text-base'>No notification found</div>
              )}
              <p className='border-t text-lg text-center font-bold pt-2 px-4 w-full'>
                <Link to={`/notifications`} className='hover:text-gray-500'>
                  VIEW ALL
                </Link>
              </p>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import classNames from "classnames";
import React, { ChangeEvent, HTMLInputTypeAttribute } from "react";

interface InputProps {
  label?: string;
  placeholder?: string;
  className?: string;
  type?: HTMLInputTypeAttribute;
  error?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
}

export default function Input({
  label,
  placeholder,
  className,
  type,
  error,
  onChange,
  readOnly = false,
}: InputProps) {
  return (
    <div
      className={`flex flex-col gap-1 ${className} ${
        className === "hide" && "hidden"
      }`}>
      <label htmlFor={label}>
        {label && (
          <div className='md:text-lg text-sm font-normal text-[#040821] mb-1 mx-1'>
            {label}
          </div>
        )}
        <input
          id={label}
          type={type}
          placeholder={placeholder}
          className={classNames(
            "text-field w-full h-[45px] border border-[#00000033] pl-5 bg-transparent rounded-lg outline-none",
            { error: error }
          )}
          onChange={onChange}
          readOnly={readOnly}
        />
      </label>
      {error && (
        <span className='error-text text-[#FF0101] text-[12px] md:text-sm mx-2'>
          {error}
        </span>
      )}
    </div>
  );
}

import apiRequest from "../../hooks/use-request";

const token = localStorage.getItem("token");

interface ErrorResponseType {
  message: string;
}

function isErrorResponseType(error: unknown): error is ErrorResponseType {
  return typeof error === "object" && error !== null && "message" in error;
}

const getNotifications = async (chatId: string) => {
  const { makeRequest: getNotifications } = apiRequest(
    `/notifications/`,
    "GET"
  );
  const response = await getNotifications();

  return response;
};

const notificationService = {
  getNotifications,
  isErrorResponseType,
};

export default notificationService;

const Error401 = () => {
  return (
    <div className='flex flex-col gap-6 h-[60vh] justify-center md:items-center px-5'>
      <p className='font-bold text-[22px] text-center'>
        Your Session has expired
      </p>
      <p className='text-md text-center'>
        Got back to platform to log in to the mediation center
      </p>
    </div>
  );
};

export default Error401;

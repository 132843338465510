import React from "react";
import { useAppSelector } from "../../app/hook";
import ListItem from "./listItem";

const List = () => {
  const { channels } = useAppSelector((state) => state.channels);
  return (
    <div className='flex flex-col gap-4 sm:gap-8'>
      {channels.channels.map((channel) => (
        <ListItem
          key={channel.caseID}
          caseID={channel.caseID}
          reqTitle={channel.title}
          status={channel.status}
          createdAt={channel.createdAt}
          chatID={channel._id}
        />
      ))}
    </div>
  );
};

export default List;

const baseURL =
  process.env.REACT_APP_ENV === "prod"
    ? process.env.REACT_APP_BACKEND_URL
    : "https://mediation.vendpal.ng";

export default function apiRequest(
  endpoint: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  headers?: Record<string, any>
) {
  const token = localStorage.getItem("token");

  async function makeRequest(data?: any, params?: Record<string, any>) {
    const searchParams = new URLSearchParams(params).toString();
    const urlWithParams = searchParams
      ? `${baseURL}${endpoint}?${searchParams}`
      : `${baseURL}${endpoint}`;

    const response = await fetch(urlWithParams, {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      body:
        method === "POST" || method === "PUT" || method === "DELETE"
          ? JSON.stringify(data)
          : undefined,
    });

    const res = await response.json();

    if (response.ok) {
      return res;
    } else {
      throw new Error(JSON.stringify(res));
    }
  }

  return { makeRequest };
}

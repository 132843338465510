import { AsyncThunkAction } from "@reduxjs/toolkit";
import apiRequest from "../../hooks/use-request";
import fileUpload from "../../hooks/use-upload";

const token = localStorage.getItem("token");

interface ErrorResponseType {
  message: string;
}

interface AllChannelsQueryParam {
  title?: string;
  status?: "active" | "cancelled" | "closed";
  limit?: number;
  page?: number;
}

function isErrorResponseType(error: unknown): error is ErrorResponseType {
  return typeof error === "object" && error !== null && "message" in error;
}

const getChannels = async (queryParams: AllChannelsQueryParam) => {
  const { makeRequest: getChannels } = apiRequest("/channels/user", "GET");
  const { makeRequest: getChannelsStats } = apiRequest(
    "/channels/user/statistics",
    "GET"
  );
  const response = await getChannels(undefined, queryParams);
  const statistics = await getChannelsStats();

  return { statistics, response };
};

const createChannel = async (userData: any) => {
  const { makeRequest: createChannel } = apiRequest("/channels/start", "POST");
  const response = await createChannel(userData);

  return response;
};

const channelsService = {
  getChannels,
  createChannel,
  isErrorResponseType,
};

export default channelsService;

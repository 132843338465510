import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import notificationService from "./notificationService";
import type { RootState } from "../../app/store";

interface INotification {
  email?: string;
  title?: string;
  content?: string;
  read?: string;
  createdAt?: string;
  updatedAt?: string;
  [key: string]: any;
}

interface SliceState {
  notifications: {
    page: number;
    totalPages: number;
    count: number;
    notifications: INotification[];
  };
  isLoading: boolean;
  isError: boolean;
  error: string | any;
}

const initialState: SliceState = {
  notifications: { page: 1, totalPages: 1, count: 1, notifications: [] },
  isLoading: false,
  isError: false,
  error: "",
};

// Get notification
export const getNotifications = createAsyncThunk(
  "/notification/getNotification",
  async (userData: any, thunkAPI) => {
    try {
      const response = await notificationService.getNotifications(userData);
      return response;
    } catch (error: unknown) {
      if (notificationService.isErrorResponseType(error)) {
        return thunkAPI.rejectWithValue(JSON.parse(error.message));
      } else {
        console.error("Unknown error occurred:", error);
        return thunkAPI.rejectWithValue(`Unknown error occurred: ${error}`);
      }
    }
  }
);

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.error = "";
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload.data.data;
        state.isLoading = false;
        state.isError = false;
        state.error = "";
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset } = notificationSlice.actions;
export const selectCount = (state: RootState) => state.notifications;
export default notificationSlice.reducer;

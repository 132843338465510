import classNames from "classnames";
import React from "react";

interface PagnitionProps {
  page: number;
  totalPages: number;
  otherParams?: any;
  dispatch?: any;
  getUserChannels?: any;
}

const Pagnition: React.FC<PagnitionProps> = ({
  page,
  totalPages,
  otherParams,
  dispatch,
  getUserChannels,
}) => {
  function handlePagnition(direction: string) {
    const queryParams = {
      ...otherParams,
      page: direction === "next" ? page + 1 : page - 1,
    };
    dispatch(getUserChannels(queryParams));
  }

  return (
    <div className='flex justify-between mb-8 px-2'>
      <div className='flex justify-between gap-2'>
        <button
          className={classNames(
            `rounded-lg border border-[#000000] p-1 px-3 `,
            {
              "border-gray-400 bg-gray-400 text-white": page === 1,
            }
          )}
          disabled={page === 1}
          onClick={() => handlePagnition("prev")}>
          Prev
        </button>
        <button
          className={classNames(`rounded-lg border border-[#000000] px-2 `, {
            "border-gray-400 bg-gray-400 text-white": page === totalPages,
          })}
          disabled={page === totalPages}
          onClick={() => handlePagnition("next")}>
          Next
        </button>
      </div>
      <div>
        Page {page} of {totalPages}
      </div>
    </div>
  );
};

export default Pagnition;

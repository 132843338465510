import React, { ReactNode } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

interface ButtonProps {
  children: ReactNode;
  variant: "primary" | "secondary" | "in-chat" | "cancel";
  size: "xs" | "sm" | "md" | "lg";
  type?: "button" | "submit" | "reset";
  href?: string;
  className?: string;
  onClick?: (e?: unknown) => void;
}

const Button = ({
  size,
  variant,
  children,
  type,
  href,
  onClick,
  className,
}: ButtonProps) => {
  return (
    <>
      {href ? (
        <Link
          to={href}
          className={classNames(`rounded-lg text-center ${className} `, {
            "p-3 px-8 font-semibold": size === "lg",
            "p-3 px-10 font-semibold": size === "md",
            "px-5 sm:px-6 py-2": size === "sm",
            "text-[10px] sm:text-sm py-1": size === "xs",
            "bg-[#1E1E1E] text-[#FFF] hover:opacity-75": variant === "primary",
            "text-[#0979A1]": variant === "secondary",
          })}>
          <button>{children}</button>
        </Link>
      ) : (
        <button
          className={classNames(`rounded-lg ${className} `, {
            "p-3 px-8 font-semibold": size === "lg",
            "p-3 px-10 font-semibold": size === "md",
            "px-5 sm:px-6 py-2": size === "sm",
            "text-[10px] sm:text-sm py-1": size === "xs",
            "bg-[#1E1E1E] text-[#FFF] hover:opacity-75": variant === "primary",
            "bg-white text-[#000000] border border-[#00000080] hover:opacity-75":
              variant === "cancel",

            "text-[#0979A1]": variant === "secondary",
          })}
          type={type}
          onClick={onClick}>
          {children}
        </button>
      )}
    </>
  );
};

export default Button;

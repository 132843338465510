function getStatusBgColor(status: string) {
  switch (status) {
    case "active":
      return "bg-[#D1FFC9]";
    case "closed":
      return "bg-[#FCCFCF]";
    case "cancelled":
      return "bg-[#CFF0FC]";
    default:
      return {
        containerFlex: "",
        detailsWidth:
          "max-w-[28%] min-w-[28%] sm:max-w-[20%] sm:min-w-[20%] md:max-w-[15%] md:min-w-[15%] ",
      };
  }
}

function getStatusDotColor(status: string) {
  switch (status) {
    case "active":
      return "bg-[#057517]";
    case "closed":
      return "bg-[#A10909]";
    case "cancelled":
      return "bg-[#0979A1]";
    default:
      return {
        containerFlex: "",
        detailsWidth:
          "max-w-[28%] min-w-[28%] sm:max-w-[20%] sm:min-w-[20%] md:max-w-[15%] md:min-w-[15%] ",
      };
  }
}

export { getStatusBgColor, getStatusDotColor };

import ThemeRoutes from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/navbar";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const isChatPath = location.pathname.startsWith("/request/chat");

  return (
    <>
      {!isChatPath ? (
        <div className=''>
          <Navbar />
          <div className='max-w-screen-xl mx-auto bg-[#F8F8F8] px-4 '>
            <ThemeRoutes />
          </div>
        </div>
      ) : (
        <ThemeRoutes />
      )}
      <ToastContainer />
    </>
  );
}
export default App;

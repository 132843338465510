import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

const Back = () => {
  const navigate = useNavigate();
  return (
    <div className='my-3 md:my-6 w-[100%] mx-auto items-center justify-start'>
      <div className='flex justify-start'>
        <div
          className='p-1 border border-[#00000080] rounded-lg text-2xl sm:text-3xl'
          onClick={() => navigate(-1)}>
          <BiArrowBack className='text-[#292D32]' />
        </div>
      </div>
    </div>
  );
};

export default Back;

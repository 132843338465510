import React, { useEffect, useState } from "react";
import Back from "../../components/back";
import Button from "../../components/button";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { useParams } from "react-router-dom";
import { getUserChannels } from "../../features/mediationCases/channelsSlice";
import { getStatusBgColor, getStatusDotColor } from "../../utils/status-color";
import { formatDateLong } from "../../utils/helper-functions";
import { Modal } from "antd";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

const ViewRequest = () => {
  const { id } = useParams();
  const { channels } = useAppSelector((state) => state.channels);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageModalVisible, setImageModalVisible] = useState(false);

  const channel: any = channels.channels.find(
    (channel, index) => channel._id === id
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!channels.channels.length && localStorage.getItem("token")) {
      dispatch(getUserChannels({}));
    }
  }, []);

  const {
    caseID,
    title,
    platform,
    transactionNumber,
    status,
    createdAt,
    description,
    attachment,
    _id,
  } = channel
    ? channel
    : {
        caseID: "",
        title: "",
        platform: "",
        transactionNumber: "",
        status: "",
        createdAt: "",
        description: "",
        attachment: "",
        _id: "",
      };

  return (
    <div className='my-4'>
      <div className='flex justify-between gap-6 sm:gap-10 items-center'>
        <div className='flex items-center gap-6'>
          <div>
            <Back />
          </div>
          <p className='font-bold text-sm sm:text-base'>
            Mediation request details
          </p>
        </div>

        <div
          className={`${getStatusBgColor(
            status
          )} py-1/2 sm:py-1 px-4 sm:px-10 rounded-full text-center flex items-center justify-center`}>
          <div
            className={`w-1 h-1 inline mr-2 rounded-full ${getStatusDotColor(
              status
            )}`}></div>
          <span className='capitalize'>{status}</span>
        </div>
      </div>
      <div className='flex flex-col flex-wrap justify-between gap-y-6 px-3 md:flex-row mt-4'>
        {/* <div className='my-3 mx-1'> */}
        {/* CASE ID */}
        <div className='md:w-[47%] flex flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Case ID</p>
          <p className='font-semibold text-base'>{caseID}</p>
        </div>
        <div className='md:w-[47%] flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Title</p>
          <p className='font-semibold text-base'>{title}</p>
        </div>
        <div className='md:w-[47%] flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Issue</p>
          <p className='font-semibold text-base'>{title}</p>
          {/*//issue is missing in api response */}
        </div>
        <div className='md:w-[47%] flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Platform</p>
          <p className='font-semibold text-base'>{platform}</p>
        </div>
        <div className='md:w-[47%] flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Order/transaction no</p>
          <p className='font-semibold text-base'>{transactionNumber}</p>
        </div>
        <div className='md:w-[47%] flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Date of Report </p>
          <p className='font-semibold text-base'>
            {formatDateLong(channel?.createdAt)}
          </p>
        </div>
        <div className='md:w-[100%] flex flex flex-col gap-4 mb-5'>
          <p className='text-sm'>Description</p>
          <p className='font-semibold text-base'>{description}</p>
        </div>
        {channel?.attachment?.length > 0 && (
          <div className='w-[100%] flex flex-col gap-2 mb-5'>
            <div className='flex justify-between'>
              <p className='text-sm'>Media</p>
              <p
                className='text-sm mx-2 hover:cursor-pointer'
                onClick={() => {
                  setImageIndex(0);
                  setImageModalVisible(true);
                }}>
                View all
              </p>
            </div>
            <div className='flex justify-center items-center gap-3 overflow-x-auto scroll-smooth p-2 rounded-lg  border border-[#00000033]'>
              {channel?.attachment?.map(
                (attachement: string, index: number) => (
                  <div
                    className='w-36 flex items-center hover:cursor-pointer'
                    key={attachement}
                    onClick={() => {
                      setImageIndex(index);
                      setImageModalVisible(true);
                    }}>
                    <img src={attachement} />
                  </div>
                )
              )}
            </div>
          </div>
        )}
        <div className='w-[100%] flex items-center justify-center mb-5'>
          <Button
            variant='primary'
            size='lg'
            className='w-[70%]'
            href={`/request/chat/${_id}`}>
            Go To Chat
          </Button>
        </div>
      </div>
      <Modal
        width={"85dvw"}
        styles={{ body: { height: "80dvh" } }}
        open={imageModalVisible}
        onCancel={() => setImageModalVisible(false)}
        className='relative top-10'
        footer={null}>
        {attachment?.length > 0 && (
          <div className='w-[100%] h-[100%] my-5 flex justify-between'>
            {imageIndex > 0 ? (
              <div
                className='flex items-center px-1 hover:cursor-pointer hover:bg-[#0000002F]'
                onClick={() => {
                  setImageIndex(imageIndex - 1);
                }}>
                <ArrowLeft2 size='32' color='#000000' />
              </div>
            ) : (
              <div></div>
            )}
            <div className='flex grow justify-center items-center gap-3 overflow-x-auto scroll-smooth p-2 rounded-lg'>
              <img src={attachment[imageIndex].url} />
            </div>
            {imageIndex < attachment.length - 1 && (
              <div
                className='flex items-center px-1 hover:cursor-pointer hover:bg-[#0000002F]'
                onClick={() => {
                  setImageIndex(imageIndex + 1);
                }}>
                <ArrowRight2 size='32' color='#000000' />
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ViewRequest;

import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import channelsReducer from "../features/mediationCases/channelsSlice";
import chatReducer from "../features/chat/chatSlice";
import notificationReducer from "../features/notifications/notificationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    channels: channelsReducer,
    chat: chatReducer,
    notifications: notificationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

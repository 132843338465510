export const calculateAge = (birthDateString: any): number => {
  const today = new Date();

  if (!birthDateString) return 0;

  const birthDate = birthDateString && new Date(birthDateString);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const formatDate = (dateString: string): string | null => {
  const dateObj = new Date(dateString);

  if (isNaN(dateObj.getTime())) {
    return null;
  }

  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const year = dateObj.getFullYear();

  return `${day}/${month}/${year}`;
};

export function formatDateLong(dateString: string) {
  // Create a new Date object from the date string
  const date = new Date(dateString);

  // Define months array for month names
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract month, day, and year components
  const month = months[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();

  // Return the formatted date string
  return `${month} ${day}, ${year}`;
}

export function getTimeFromDate(dateString: string) {
  const date = new Date(dateString);

  // Extract hours and minutes components
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Determine AM/PM and adjust hours accordingly
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format

  // Pad minutes with leading zero if necessary
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Return the time string in hh:mm AM/PM format
  return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export function getTimeAgo(dateString: string | undefined): string {
  if (!dateString) {
    return "Invalid date";
  }

  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  const now = new Date();
  const diff = now.getTime() - date.getTime();

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds !== 1 ? "s" : ""} ago`;
  }
}

export function truncateStringToFitContainer(
  text: string,
  containerWidth: number
): string {
  const maxTextWidth = containerWidth * 0.55; // Maximum allowed width for the text
  const span = document.createElement("span");
  span.style.visibility = "hidden";
  span.style.whiteSpace = "nowrap";
  span.innerText = text;
  document.body.appendChild(span);
  const textWidth = span.getBoundingClientRect().width;
  document.body.removeChild(span);

  if (textWidth <= maxTextWidth) {
    return text; // No truncation needed
  }

  // Calculate how many characters to keep within the max width
  const scaleFactor = maxTextWidth / textWidth;
  const truncatedLength = Math.floor(text.length * scaleFactor);

  // Truncate the string and add an ellipsis
  const truncatedText = text.slice(0, truncatedLength - 1) + "…";
  return truncatedText;
}

import React from "react";
import avatar from "../../assets/images/chat_avatar.png";
import Back from "../../components/back";
import { getTimeFromDate } from "../../utils/helper-functions";

const Chat = ({ channel, chat }: any) => {
  return (
    <div className='flex-1 overflow-y-auto max-w-screen-xl mx-auto w-full'>
      <div className='flex flex-col gap-4 md:container mx-auto px-4 my-4'>
        <div className='flex justify-between gap-3 sm:gap-6'>
          <div className='basis-10%'>
            <img src={avatar} alt='avatar' className='w-8 sm:w-11 md:w-14' />
          </div>
          <div className='flex-1 flex flex-col gap-1'>
            <div className='flex justify-between items-center'>
              <div className='text-sm md:text-lg font-semibold'>
                Mediator Admin
              </div>
              <div className='text-[10px] md:text-sm italic'>
                {channel?.createdAt}
              </div>
            </div>
            <div className='text-sm md:text-base text-justify'>
              We have received your mediation request and it will be assigned to
              a mediator soon.
            </div>
          </div>
        </div>

        {chat?.messages?.length > 0 &&
          chat.messages.map((c: any) => (
            <div className='flex justify-between gap-3 sm:gap-6'>
              <div className='basis-10%'>
                <img
                  src={avatar}
                  alt='avatar'
                  className='w-8 sm:w-11 md:w-14'
                />
              </div>
              <div className='flex-1 flex flex-col gap-1'>
                <div className='flex justify-between items-center'>
                  <div className='text-sm md:text-lg font-semibold capitalize'>
                    {c?.user.role}
                  </div>
                  <div className='text-[10px] md:text-sm italic'>
                    {getTimeFromDate(c?.createdAt)}
                  </div>
                </div>
                <div className='text-sm md:text-base text-justify'>
                  {c?.message}
                </div>
              </div>
            </div>
          ))}

        {/* <div className=''>each chat</div> */}
      </div>
    </div>
  );
};

export default Chat;

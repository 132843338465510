import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Authentication from "../auth/Auth";
import AuthGuard from "../utils/auth-guard";
import Dashboard from "../views/dashboard/Dashboard";
import ViewRequests from "../views/requests/ViewRequests";
import Error404 from "../pages/404";
import Error401 from "../pages/401";
import CreateRequest from "../views/requests/CreateRequest";
import ViewRequest from "../views/requests/ViewRequest";
import ChatContainer from "../views/chats/ChatContainer";
import Notifications from "../views/Notification/Notifications";
import Error from "../pages/unknownError";

const ThemeRoutes = () => {
  return useRoutes([
    { path: "/auth", element: <Authentication /> },
    {
      path: "/",
      element: (
        <AuthGuard>
          <Outlet />
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: <Navigate to='/dashboard' />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "/requests/create",
          element: <CreateRequest />,
        },
        {
          path: "/requests/:viewId",
          element: <ViewRequests />,
        },
        {
          path: "/request/details/:id",
          element: <ViewRequest />,
        },
        {
          path: "/request/chat/:id",
          element: <ChatContainer />,
        },
        {
          path: "notifications",
          element: <Notifications />,
        },
      ],
    },
    { path: "/401", element: <Error401 /> },
    { path: "/oops", element: <Error /> },

    { path: "/*", element: <Error404 /> },
  ]);
};

export default ThemeRoutes;
